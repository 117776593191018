import React from 'react'
 import styles from './Footer.module.css';
import Button from '@mui/material/Button';
import { useState } from 'react';

function ButtonComponent({targetUrl}) {
    const [isRepoHovered, setIsRepoHovered] = useState(false);
    const [isViewButtonHovered, setIsViewButtonHovered] = useState(false);

    const handleHover = () => {
        setIsRepoHovered(true);
    };
  
    const handleLeave = () => {
        setIsRepoHovered(false);
    };
    const handleViewHover = () => {
        setIsViewButtonHovered(true);
    };
  
    const handleViewLeave = () => {
        setIsViewButtonHovered(false);
    };
    const onViewClickHandle = () =>{
        window.open(targetUrl, '_blank');
    }
    const onRepoClickHandle = () =>{
        window.open(targetUrl, '_blank');
    }
  return (
    <>
   <Button
    onMouseEnter={handleViewHover}
    onMouseLeave={handleViewLeave}
    variant="contained"
    onClick={onViewClickHandle}
  >
    {isViewButtonHovered ? (
      <>
       View It Here{' '}
        <img
          width="24"
          height="24"
          src="https://img.icons8.com/material-rounded/24/external-link.png"
          alt="external-link"
         
        />
      </>
    ) : (
        ' View It Here'
    )}
  </Button> {' '} 

  {/* <Button
    onMouseEnter={handleHover}
    onMouseLeave={handleLeave}
    onClick={onRepoClickHandle}
    variant="contained"
  >
    {isRepoHovered ? (
      <>
        View Github Repo{' '}
        <img
          width="24"
          height="24"
          src="https://img.icons8.com/material-rounded/24/external-link.png"
          alt="external-link"
         
        />
      </>
    ) : (
        'View Github Repo'
    )}
  </Button> */}
  </>
);
}

export default ButtonComponent