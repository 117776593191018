import React from "react";
import styles from './Animation.module.css';

function Animation() {
return(
<section>
  <div className={styles.content}>
    <h2>Vanshika</h2>
    <h2>Vanshika</h2>
  </div>
</section>

)
}
export default Animation;