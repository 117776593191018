import React from 'react'
import styles from './Footer.module.css';
function Footer() {
  return (
   
        <footer>
      <div className={styles.copy}>
        {/* Built with &#x2661; by  */}
        Built with <img width="50" height="50" src="https://img.icons8.com/clouds/100/hearts.png" alt="hearts"/> by
        Vanshika Singh
      </div>
    </footer>
    )
   
  
}

export default Footer