import React from 'react'
import styles from './Social.module.css';
import { Button } from '@mui/material';
export default function Social() {


  return (

    <div className={styles.socials}>
      <a href="#"
      ><img
          width="90" height="90"
          src="https://img.icons8.com/ios-glyphs/90/github.png"
          alt="Github"
          className={styles.socicon}
        /></a>
      <a href="#"
      ><img
          width="96" height="96" src="https://img.icons8.com/fluency/96/linkedin.png"
          alt="Linkedin"
          className={styles.socicon}
        /></a>


    </div>
  )
}
