import React from 'react'
import styles from './Projects.module.css';
import stayTipsy from "../assets/images/stayTipsy.png";
import vedSearch from "../assets/images/vedSearch.png";
import coffee from  "../assets/images/coffee.png";
import ButtonComponent from '../components/Button';
// import WaitCheck from "../assets/images/WaitCheckDemo.mov";
function Projects() {
 
  return (
    <div>
      <section className={styles.projects}>
        <h2 className={styles.projectHeader}>WORK</h2>
        <h1>A Selection Of Stuff I've Built</h1>

        <div className={styles.projectsContainer}>

          <div className={styles.projectCard}>
            <div className={styles.projectImage}>
              {/* <video controls className={styles.projectPic}>
                <source src={WaitCheck} type="video/mp4" /> */}
                <img
                src={coffee}
                alt="coffee"
                loading="lazy"
                className={styles.coffee}
              />
             
            </div>
            <div className={styles.textCard}>
              <h3 className={styles.projectTitle}>Coffee Cafe</h3>
              <p className={styles.projectDetails}>
              Built a coffee website using React, Redux, Redux-Saga, React Query, and Firebase. Users can search for different coffee types, add their favorites to a list, and manage items in a shopping cart. The site uses Firebase for storing data and user management, while Redux and React Query handle state and data fetching efficiently.
              </p>
            
            <ButtonComponent  targetUrl="https://coffee-shop-91e4d.firebaseapp.com/"/>
            </div>
          </div>

          <div className={styles.projectCard}>
            <div className={styles.projectImage}>
              <img
                src={vedSearch}
                alt="ved"
                loading="lazy"
                className={styles.projectPic}
              />
            </div>
            <div className={styles.textCard}>
              <h3 className={styles.projectTitle}>Ved Search</h3>
              <p className={styles.projectDetails}>
              The app offers a comprehensive exploration of Hindu Vedas, featuring all four types and their Shlokas. Users can search for specific texts, read detailed descriptions, share content on social media, and listen to audio versions. The English translations are meticulously crafted by esteemed professors from top Indian universities to preserve the essence of the original Sanskrit text.
              </p>
              
            <ButtonComponent targetUrl="https://vedsearch.org/"/>

            </div>
          </div>
          {/* <div className={styles.projectCard}>
            <div className={styles.projectImage}>
              <img
                src={stayTipsy}
                alt="tipsy"
                loading="lazy"
                className={styles.projectPic}
              />
            </div>
            <div className={styles.textCard}>
              <h3 className={styles.projectTitle}>Stay Tipsy</h3>
              <p className={styles.projectDetails}>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas
                ratione vel inventore labore commodi modi quos culpa aut saepe!
                Alias!
              </p>
          
              <ButtonComponent targetUrl="https://github.com/VanshikaSingh/StayTipsyDiary"/>

            </div>
          </div> */}
        </div>
      </section>
    </div>
  )
}

export default Projects