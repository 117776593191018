
import './App.css';
import NavigationBar from './components/NavigationBar';
import '../src/assets/styles/globalStyles.module.css'; 
import About from './pages/About';
import Animation from './components/Animation'
import Skills from './pages/Skills';
import Projects from './pages/Projects';
import Social from './components/Social';
import Footer from './components/Footer';
import { motion, useScroll } from "framer-motion";
// import styles from '/Users/vanshikasingh/Desktop/react/portfolio/my-portfolio/src/global.module.css'


function App() {
  const { scrollYProgress } = useScroll();
  return (
    <>
    <motion.div
      className="progress-bar"
      style={{ scaleX: scrollYProgress }}
    />
   
     {/* <NavigationBar/> */}
     <About/>
     <Skills/>
     <Projects/>
     <Social/>
     <Footer/>
  
  </>
  
  );
}

export default App;
